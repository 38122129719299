import landing_chart from 'assets/images/landing_chart.png';
import {HighlightedText} from 'components/items/Headings';
import tw from 'twin.macro';

const Container = tw.div`overflow-hidden bg-white py-12 sm:py-16 `;
const InnerContainer = tw.div`mx-auto px-6 lg:px-8`;
const Grid = tw.div`mx-auto grid  grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 py-8`;
const ContentWrapper = tw.div`lg:pr-8 lg:pt-4 self-center`;
const ContentInner = tw.div`w-full flex flex-col`;
const Title = tw.h1` text-xl md:text-2xl lg:text-6xl text-left font-thin xl:px-24`;
const DescriptionContainer = tw.div`ring-4 ring-primary-500 px-2 my-12 w-2/3`;
const Description = tw.p`text-sm md:text-lg lg:text-2xl text-left`;
const ExtraBold = tw.div`text-lg md:text-xl lg:text-3xl inline`;
const Asterix = tw.div`text-sm inline align-text-top`;
const Thin = tw.div`text-left font-thin text-sm`;

const Image = tw.img`rounded-xl shadow-xl ring-1 ring-gray-400/10 w-full`;

export default () => {
  return (
    <Container>
      <InnerContainer>
        <Title>
          Unlock <HighlightedText>hidden</HighlightedText> revenue from your ​email flows
        </Title>
        <Grid>
          <ContentWrapper>
            <ContentInner>
              <DescriptionContainer style={{placeSelf: 'center'}}>
                <Description>
                  Average revenue increase on a ​welcome flow email sequence<Asterix>*</Asterix>:{' '}
                  <ExtraBold>$2.57</ExtraBold>​
                </Description>
              </DescriptionContainer>
              <DescriptionContainer style={{placeSelf: 'end'}}>
                <Description>
                  Average revenue unlocked over 12 ​Months from just the welcome flow
                  <Asterix>*</Asterix>: <ExtraBold>$21,352.20</ExtraBold>​
                </Description>
              </DescriptionContainer>
            </ContentInner>
          </ContentWrapper>
          <Image src={landing_chart} />
        </Grid>
        <Thin>
          <Asterix>*</Asterix>Based on an email list of approx. 15,000 Users
        </Thin>
      </InnerContainer>
    </Container>
  );
};
