import tw, {styled} from 'twin.macro';
import {FaCheck} from 'react-icons/fa';

const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '/signup',
    priceMonthly: '£99.99',
    description: 'The essentials to provide your best work for clients.',
    features: [
      'Unlimited Email Variations',
      'Test Up To 15 Workflows',
      'Chat + Email + Phone Support',
      'Unlimited Users',
      'Up To 5 Members Of Your Team',
    ],
    mostPopular: false,
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '/signup',
    priceMonthly: '£149.99',
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      'Unlimited Email Variations',
      'Test Up To 50 Workflows',
      'Chat + Email + Phone Support',
      'Unlimited Users',
      'Up To 10 Members Of Your Team',
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '/signup',
    priceMonthly: 'Contact us',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited Email Variations',
      'Unlimited Workflows',
      'Chat + Email + Phone Support',
      'Unlimited Users',
      'Unlimited Members Of Your Team',
    ],
    mostPopular: false,
  },
];

const Container = tw.div`bg-white py-20 sm:py-20`;
const InnerContainer = tw.div`mx-auto max-w-screen-xl px-6 lg:px-8`;
const HeaderContainer = tw.div`mx-auto max-w-lg text-center`;
const HeaderTitle = tw.h2`text-base font-semibold leading-7`;
const HeaderSubtitle = tw.p`mt-2 text-4xl font-bold tracking-tight sm:text-5xl text-primary-500`;
const Description = tw.p`mx-auto mt-6 max-w-lg text-center text-lg leading-8 text-secondary-text-dark font-semibold`;
const GridContainer = tw.div`isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3`;
const Card = styled.div(({mostPopular, tierIdx, length}) => [
  tw`flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-500 xl:p-10`,
  mostPopular ? tw`lg:z-10 lg:rounded-b-none` : tw`lg:mt-8`,
  tierIdx === 0 && tw`lg:rounded-r-none`,
  tierIdx === length - 1 && tw`lg:rounded-l-none`,
]);
const CardHeader = styled.div(({mostPopular}) => [
  tw`flex items-center justify-between gap-x-4`,
  mostPopular ? tw`text-primary-500` : tw`text-secondary-text-dark`,
]);
const CardTitle = tw.h3`text-lg font-semibold leading-8`;
const CardBadge = tw.p`rounded-full bg-primary-lightest px-2 py-1 text-xs font-semibold leading-5 text-primary-500`;
const CardDescription = tw.p`mt-4 text-sm leading-6 text-gray-600`;
const CardPrice = tw.p`mt-6 flex items-baseline gap-x-1`;
const PriceAmount = tw.span`text-4xl font-bold tracking-tight text-secondary-text-dark`;
const PriceUnit = tw.span`text-sm font-semibold leading-6 text-gray-600`;
const FeaturesList = tw.ul`mt-8 space-y-3 text-sm leading-6 text-gray-600`;
const FeatureItem = tw.li`flex gap-x-3`;
const BuyButton = styled.a(({mostPopular}) => [
  tw`mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`,
  mostPopular
    ? tw`bg-primary-500 text-white shadow-sm hover:bg-primary-900`
    : tw`text-primary-500 ring-1 ring-inset ring-primary-500 hover:ring-primary-900 hover:text-primary-900`,
]);

export default function Example() {
  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <HeaderTitle>Pricing</HeaderTitle>
          <HeaderSubtitle>1 MONTH FREE TRIAL</HeaderSubtitle>
        </HeaderContainer>
        <Description>
          We offer a 1 month free trial for all users to test out and experience the power of
          MailBandit.
        </Description>
        <GridContainer>
          {tiers.map((tier, tierIdx) => (
            <Card
              key={tier.id}
              mostPopular={tier.mostPopular}
              tierIdx={tierIdx}
              length={tiers.length}
            >
              <div>
                <CardHeader mostPopular={tier.mostPopular}>
                  <CardTitle id={tier.id}>{tier.name}</CardTitle>
                  {tier.mostPopular && <CardBadge>Most popular</CardBadge>}
                </CardHeader>
                <CardDescription>{tier.description}</CardDescription>
                <CardPrice>
                  <PriceAmount>{tier.priceMonthly}</PriceAmount>
                  <PriceUnit>/month</PriceUnit>
                </CardPrice>
                <FeaturesList role="list">
                  {tier.features.map(feature => (
                    <FeatureItem key={feature}>
                      <FaCheck />
                      {feature}
                    </FeatureItem>
                  ))}
                </FeaturesList>
              </div>
              <BuyButton href={tier.href} aria-describedby={tier.id} mostPopular={tier.mostPopular}>
                Sign Up
              </BuyButton>
            </Card>
          ))}
        </GridContainer>
      </InnerContainer>
    </Container>
  );
}
