import RWH_Logo from 'assets/images/logo_cloud/RWH.png';
import tw from 'twin.macro';
import {HighlightedText} from '../items/Headings';

const Container = tw.div`container w-11/12 py-12 my-12 mx-auto border-white rounded-lg border-4 px-12 flex flex-col`;
const Grid = tw.div`grid grid-cols-1 gap-8 md:grid-cols-2 `;
const Card = tw.div`my-10`;
const Title = tw.h1`font-bold text-white text-xl md:text-2xl lg:text-5xl text-center `;
const Section = tw.section`bg-[#202122] py-12`;
const Description = tw.p`mt-10 text-white text-center text-sm md:text-lg w-full md:w-2/3 self-center`;
const Image = tw.img`inline w-1/3 md:w-1/3 px-2 py-2 bg-white mx-1`;

const TwoColHeaders = () => (
  <Section>
    <Container>
      <Card>
        <Title>
          AI-Powered A/B<HighlightedText>(C/D/E/F/G)</HighlightedText> ​testing on autopilot
        </Title>
      </Card>
      <Grid>
        <Card>
          <Title>
            <HighlightedText>Making</HighlightedText> you <br />
            more money.
          </Title>
        </Card>

        <Card>
          <Title>
            {' '}
            <HighlightedText>Saving </HighlightedText> you <br />
            time.
          </Title>
        </Card>
      </Grid>
      <Description>
        See how RoseWaterHouse dynamically tested their sign-up offers and generated an{' '}
        <HighlightedText>additional 12% revenue </HighlightedText>per email.
      </Description>
    </Container>
  </Section>
);

export default TwoColHeaders;
