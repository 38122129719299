import logo7 from 'assets/images/klaviyo-primary-logo-charcoal-medium.png';
import logo1 from 'assets/images/logo_cloud/damson_madder.webp';
import logo2 from 'assets/images/logo_cloud/jaded.webp';
import logo3 from 'assets/images/logo_cloud/kim_shui.webp';
import logo4 from 'assets/images/logo_cloud/lamoda.webp';
import logo5 from 'assets/images/logo_cloud/misfits.webp';
import logo6 from 'assets/images/logo_cloud/pythia.webp';
import logo8 from 'assets/images/logo_cloud/RWH.png';
import tw from 'twin.macro';
import {Container} from '../items/Layouts';

const InnerContainer = tw.div`mx-auto p-6 lg:p-12 max-w-screen-xl`;
const Heading = tw.h2`text-center text-lg font-semibold leading-8 text-secondary-text-dark`;
const GridContainer = tw.div`mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-10 my-10 lg:grid-cols-5 sm:gap-x-10 lg:mx-0 lg:max-w-none items-center`;
const Image = tw.img`col-span-1 object-contain justify-self-center`;
const PlatformImage = tw.img`col-span-2 object-contain lg:col-span-1 w-20 md:w-24 lg:w-32 inline`;
const PlatformText = tw.h2`text-center`;

export default () => {
  return (
    <Container>
      <InnerContainer>
        <Heading>TRUSTED BY:</Heading>
        <GridContainer>
          <div />
          <Image src={logo6} alt="pythia" />
          <div />
          <Image src={logo8} alt="RoseWaterHouse" />
          <div />
        </GridContainer>
        <PlatformText>
          Currently supporting brands using <PlatformImage src={logo7} />
        </PlatformText>
      </InnerContainer>
    </Container>
  );
};
