import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {firestore} from 'configuration/firebase/Firebase';
import {Experiment} from '../../objects/Experiment';

export const setupNewExperiment = async newExperiment => {
  try {
    const start = Timestamp.fromDate(newExperiment.startDate);
    const experimentCollectionReference = collection(firestore, 'experiments');
    const docRef = await addDoc(experimentCollectionReference, {
      name: newExperiment.name,
      active_variants: newExperiment.activeVariants,
      active: newExperiment.isRunning,
      start_datetime: start,
      platform: 'klaviyo_flow',
      team: doc(firestore, 'teams/' + newExperiment.team),
      metric: {
        id: newExperiment.metricId,
        name: newExperiment.metricName,
      },
      EMA_Split_Total_Revenue: 0,
      EMA_MB_Total_Revenue: 0,
      total_actual_rev: 0,
    });
    return docRef.id;
  } catch (error) {
    console.error(error);
    throw new Error('Unable to create new experiment, please try again!');
  }
};

export const getActiveExperimentsByTeam = async team => {
  try {
    const q = query(
      collection(firestore, 'experiments'),
      where('team', '==', doc(firestore, 'teams/' + team)),
      where('active', '==', true)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const experiment = doc.data();
      return new Experiment(
        experiment.name,
        experiment.active_variants,
        experiment.active,
        experiment.start_datetime.toDate(),
        experiment.team,
        doc.id
      );
    });
  } catch (error) {
    console.error(error);
  }
};

export const getExperiment = async id => {
  try {
    const expRef = doc(firestore, 'experiments', id);
    const expSnap = await getDoc(expRef);
    if (expSnap.exists()) {
      const experiment = expSnap.data();
      return new Experiment(
        experiment.name,
        experiment.active_variants,
        experiment.active,
        experiment.start_datetime.toDate(),
        experiment.team,
        expSnap.id,
        experiment.metric.id,
        experiment.metric.name,
        experiment.EMA_MB_Total_Revenue,
        experiment.EMA_Split_Total_Revenue,
        experiment.total_actual_rev
      );
    } else {
      throw new Error('No such experiment!');
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get experiment, please try again!');
  }
};

export const setExperimentInactive = async id => {
  const expRef = doc(firestore, 'experiments', id);
  await updateDoc(expRef, {
    active: false,
  });
};
