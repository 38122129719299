import icon1 from 'assets/images/landing_3_1.webp';
import icon2 from 'assets/images/landing_3_2.webp';
import icon3 from 'assets/images/landing_3_3.webp';
import {Link} from 'react-router-dom';
import tw from 'twin.macro';

const Container = tw.div`container px-6 py-12 mx-auto `;
const Grid = tw.div`grid grid-cols-1 lg:grid-cols-3 gap-y-16 pt-8 col-end-auto justify-items-center`;
const Card = tw.div`text-center w-3/4 `;
const ActionCard = tw.div`text-center mt-16`;
const AspectContainer = tw.div` overflow-hidden flex justify-center aspect-h-1 aspect-w-2 mb-12`;
const Title = tw.h1`font-bold text-lg md:text-3xl xl:text-4xl text-center `;
const Description = tw.p` mt-2 lg:mt-10 text-sm lg:text-lg`;
const Section = tw.section`bg-primary-200 `;
const Image = tw.img`w-1/2 justify-self-center m-auto`;
const StrikeThrough = tw.p`line-through`;
const PrimaryAction = tw(
  Link
)` px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-[#1A150F] border-2 font-bold shadow transition duration-300 text-gray-100 hocus:text-gray-200 focus:outline-none focus:shadow-outline text-center`;

const ThreeColIconsDescription = () => (
  <Section>
    <Container>
      <Grid>
        <Card>
          <AspectContainer>
            <Image src={icon1} />
          </AspectContainer>
          <Title>Dynamic Email Allocations</Title>
          <Description>
            Optimizing throughout the day, every day, to send the most successful test variant{' '}
          </Description>
        </Card>

        <Card>
          <AspectContainer>
            <Image src={icon2} />
          </AspectContainer>
          <Title>Multi Variant Testing</Title>
          <Description>
            <StrikeThrough>A/B</StrikeThrough>
            <br /> A/B/C/D/E/F/G
          </Description>
        </Card>

        <Card>
          <AspectContainer>
            <Image src={icon3} />
          </AspectContainer>
          <Title>Email Test Suggestions</Title>
          <Description>Coming Soon </Description>
        </Card>
      </Grid>
      <ActionCard>
        <PrimaryAction to={'/signup'}>TRY US FREE</PrimaryAction>
      </ActionCard>
      <ActionCard>
        <p>
          No Credit Card Required. <br /> ​No Account Limitations.
        </p>
      </ActionCard>
    </Container>
  </Section>
);

export default ThreeColIconsDescription;
