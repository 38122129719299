import {createGlobalStyle} from 'styled-components';
import {globalStyles} from 'twin.macro';
import MyFontFile from '../assets/fonts/Aktiv-Grotesk-Ex.ttf';

const GlobalStyles = createGlobalStyle(
  globalStyles,
  `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
 @font-face {
    font-family: "Aktiv Grotesk Ex";
    src: url(${MyFontFile})format('truetype');
    }
   
`
);

export default GlobalStyles;
