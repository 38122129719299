import React, {useContext, useEffect, useState} from 'react';
import Header from 'components/headers/light';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import tw from 'twin.macro';
import {DashboardHeading, HighlightedText, Subheading} from 'components/items/Headings';
import {OptionsButton, RedButton} from 'components/items/Buttons';
import {useNavigate} from 'react-router-dom';
import {Experiment} from 'helpers/objects/Experiment';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {InputField} from 'components/forms/InputField';
import {MultiInputField} from 'components/forms/MultiInputField';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {setupNewExperiment} from '../helpers/api/firestore/Experiments';
import {getCurrentTeam} from '../helpers/api/firestore/Teams';
import {SectionDescription, ValidationError} from '../components/items/Typography';
import {Modal, ModalCopyLineItem} from '../components/items/Modals';
import {fetchAvailableMetrics} from '../helpers/api/functions/Metrics';
import SimpleCustomSelectMenu from '../components/forms/SimpleCustomSelectMenu';
import {TourContext} from '../helpers/tour/TourContext';
import {fetchTemplate} from 'helpers/api/functions/Templates';
import {Variant} from 'helpers/objects/Variant';
import {setVariantFields} from 'helpers/api/firestore/Variants';

export default () => {
  const Form = tw.form`border-2 rounded-md h-full p-5`;
  const Column = tw.div`w-full flex flex-col pt-5 items-center`;
  const CreateButtonContainer = tw.div`w-full flex justify-center text-primary-500 mt-10`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isWebhookModalOpen, setIsWebhookModalOpen] = useState(false);
  const [isMissingAPIKey, setIsMissingAPIKey] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(undefined);
  const [user] = IsLoggedIn();
  const ItemContainer = tw.div`w-full items-center mt-5`;
  const SemiBoldContainer = tw.div`font-semibold`;
  const [continueTour, setContinueTour] = useState(false);

  const [modalCopyItems, setModalCopyItems] = useState([
    {
      title: 'Destination URL:',
      text: 'https://webhook-publication-service-6wnqxu53tq-uc.a.run.app/',
    },
  ]);
  const [modalCopyStates, setModalCopyStates] = useState(Array(modalCopyItems.length).fill(false));
  const [numberOfFields, setNumberOfFields] = useState(2);
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);

  const createExperiment = async (
    experimentName,
    metricName,
    metricId,
    templateIDs,
    subjectLines,
    previewHeaders
  ) => {
    const isRunning = true;
    const startDate = new Date();
    const team = await getCurrentTeam(user.uid);
    const experimentToCreate = new Experiment(
      experimentName,
      templateIDs,
      isRunning,
      startDate,
      team,
      undefined,
      metricId,
      metricName,
      0,
      0,
      0
    );
    try {
      const templatesDetails = await Promise.all(
        templateIDs.map(async (templateId, index) => {
          return await fetchTemplate(user.uid, templateId);
        })
      );
      const experimentId = await setupNewExperiment(experimentToCreate);
      templateIDs.forEach((templateId, index) => {
        const variantToUpload = new Variant(
          experimentId,
          templateId,
          subjectLines[index],
          previewHeaders[index],
          templatesDetails[index]['attributes']
        );
        setVariantFields(variantToUpload);
      });
      return experimentId;
    } catch (e) {
      throw e;
    }
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData(e.target);
    const experimentName = data.get('ExperimentName');
    const templateURLs = Array.from(
      {length: numberOfFields},
      (_, i) => 'TemplateID-' + (i + 1).toString()
    ).map(item => data.get(item));
    const templateIDs = templateURLs.map(url => url.slice(-6));
    const subjectLines = Array.from(
      {length: numberOfFields},
      (_, i) => 'SubjectLine-' + (i + 1).toString()
    ).map(item => data.get(item));
    const previewHeaders = Array.from(
      {length: numberOfFields},
      (_, i) => 'PreviewHeader-' + (i + 1).toString()
    ).map(item => data.get(item));
    const metricIndex = e.target.metric.selectedIndex;
    const metricName = Object.keys(availableMetrics)[metricIndex];
    const metricId = Object.values(availableMetrics)[metricIndex]['id'];

    createExperiment(
      experimentName,
      metricName,
      metricId,
      templateIDs,
      subjectLines,
      previewHeaders
    )
      .then(id => {
        const jsonBody = '{"email": "{{ person.email }}", "experiment_id": "' + id + '"}';
        setLoading(false);
        setModalCopyItems(prev => [...prev, {text: jsonBody, title: 'JSON body:'}]);
        setModalCopyStates(prevState => [...prevState, false]);
        setIsWebhookModalOpen(true);
      })
      .catch(e => {
        setIsErrorModal('Error creating experiment, please try again later!');
        setLoading(false);
      });
  };

  useEffect(() => {
    // Dynamic validation scheme
    const commonValidations = {
      ExperimentName: Yup.string()
        .required('Experiment Name required.')
        .min(2, 'Experiment Name is too short.'),
    };
    const indices = Array.from({length: numberOfFields}, (_, index) => index + 1);
    const dynamicValidations = indices.reduce((acc, index) => {
      return {
        ...acc,
        [`TemplateID-${index}`]: Yup.string()
          .required(`Template URL ${index} required.`)
          .min(2, `Template url ${index} is too short.`)
          .matches(
            'https:\\/\\/www\\.klaviyo\\.com\\/email-template-editor\\/[A-Za-z0-9]{6}',
            'URL of Klaviyo template required in the form "https://www.klaviyo.com/email-template-editor/....."'
          ),
        [`SubjectLine-${index}`]: Yup.string()
          .required(`Subject Line ${index} required.`)
          .min(2, `Subject Line ${index} is too short.`),
        [`PreviewHeader-${index}`]: Yup.string()
          .required(`Preview Header ${index} required.`)
          .min(2, `Preview Header ${index} is too short.`),
      };
    }, {});

    setValidationSchema(Yup.object().shape({...dynamicValidations, ...commonValidations}));
  }, [numberOfFields]);

  useEffect(() => {
    fetchAvailableMetrics(user.uid)
      .then(r => {
        setAvailableMetrics(r);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        if (!run) {
          setIsMissingAPIKey(true);
        }
      });
  }, []);

  useEffect(() => {
    if (run && loading) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && !loading) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading]);

  if (loading) {
    return (
      <LoadingContainer>
        <PageLoading />
      </LoadingContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <ContentWithPaddingXl id={'create-experiment-body'}>
        <Modal
          isOpen={isWebhookModalOpen}
          modalHeading={'Experiment created!'}
          modalBody={
            <>
              <Subheading>
                1) In your Klaviyo flow, replace the relevant Email action with a Webhook. This
                webhook will send the email variations for you.
              </Subheading>
              <br />
              <Subheading>2) Insert the following details in your Webhook:</Subheading>
              <br />
              {modalCopyItems.map((item, index) => (
                <>
                  <SectionDescription>{item.title}</SectionDescription>
                  <ModalCopyLineItem
                    text={item.text}
                    iconStates={modalCopyStates}
                    index={index}
                    setIconStates={setModalCopyStates}
                  />
                  <br />
                </>
              ))}
              <ValidationError>
                Warning: Once you leave, you will not be able to find these details again.
              </ValidationError>
              <br />
              <br />
              <Subheading>3) Set your Webhook to live and you're done!</Subheading>
            </>
          }
          modalButtons={[
            {
              text: 'Finished',
              action: () => {
                navigate('/dashboard');
              },
              buttonTag: RedButton,
            },
          ]}
        />
        <Modal
          isOpen={isMissingAPIKey}
          modalHeading={'Missing API Key'}
          modalBody={
            <Subheading>
              Your API Key seems to be incorrect or missing, please set it up to create an
              experiment.
            </Subheading>
          }
          modalButtons={[
            {
              text: 'Set API Key',
              action: () => {
                navigate('/config');
              },
              buttonTag: RedButton,
            },
          ]}
        />
        <Modal
          isOpen={isErrorModal !== undefined}
          modalHeading={'Error'}
          modalBody={<Subheading>{isErrorModal}</Subheading>}
          modalButtons={[
            {
              text: 'Close',
              action: () => {
                setIsErrorModal(undefined);
              },
              buttonTag: RedButton,
            },
          ]}
        />
        <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({props, isValid, touched}) => {
            return (
              <Form onSubmit={handleSubmit}>
                <DashboardHeading>
                  {' '}
                  Create&nbsp; <HighlightedText>New</HighlightedText> &nbsp;Experiment
                </DashboardHeading>
                <Column>
                  <ItemContainer id={'create-experiment-name'}>
                    <SemiBoldContainer>
                      <InputField
                        id="ExperimentName"
                        name="ExperimentName"
                        label="Experiment Name"
                        tooltipContent="Give your experiment a name."
                      />
                    </SemiBoldContainer>
                  </ItemContainer>
                  <ItemContainer id={'create-experiment-metric'}>
                    <SimpleCustomSelectMenu
                      htmlId={'metric'}
                      label={'Select Metric'}
                      options={Object.values(availableMetrics).map(
                        r => r.name + ' - ' + r.integration_name
                      )}
                      tooltipContent={'Select the metric you would like to optimize for.'}
                    />
                  </ItemContainer>
                  <ItemContainer id="create-experiment-variants">
                    <MultiInputField
                      fields={[
                        {
                          label: 'Template URL',
                          inputName: 'TemplateID',
                          tooltipContent: 'Insert the URL of the template you wish to test.',
                        },
                        {
                          label: 'Subject Line',
                          inputName: 'SubjectLine',
                          tooltipContent: 'Insert the subject line you wish to test.',
                        },
                        {
                          label: 'Preview Header',
                          inputName: 'PreviewHeader',
                          tooltipContent: 'Insert the preview header you wish to test.',
                        },
                      ]}
                      addFieldLabel={'+ Add Variant'}
                      removeFieldLabel={'- Remove Variant'}
                      numberOfFields={numberOfFields}
                      setNumberOfFields={setNumberOfFields}
                      minNumberOfFields={2}
                    />
                  </ItemContainer>
                </Column>
                <CreateButtonContainer id={'create-experiment-button'}>
                  <OptionsButton
                    type={'submit'}
                    name={'submit'}
                    disabled={!isValid || Object.keys(touched).length === 0}
                  >
                    Create Experiment
                  </OptionsButton>
                </CreateButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
