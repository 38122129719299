import large_email_icon from 'assets/images/large_email_icon.png';
import {FaFacebook, FaInstagram, FaLinkedin, FaTiktok} from 'react-icons/fa';
import {FaXTwitter} from 'react-icons/fa6';
import {Link} from 'react-router-dom';
import tw from 'twin.macro';

const Container = tw.div`overflow-hidden bg-[#202122] py-12 sm:py-16 `;
const InnerContainer = tw.div`mx-auto px-6 lg:px-8`;
const Grid = tw.div`mx-auto grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 xl:grid-cols-3 p-8 `;
const ContentWrapper = tw.div`lg:pr-8 lg:pt-4 self-center`;
const ContentInner = tw.div`w-full`;
const Title = tw.h1` text-xl md:text-2xl lg:text-6xl text-left text-white leading-none`;
const Description = tw.p`text-sm md:text-lg lg:text-2xl text-left text-white py-4`;
const Image = tw.img`w-1/2 lg:w-3/4`;
const SmallHiddenDiv = tw.div`hidden xl:block `;
const ActionCard = tw.div`mt-16`;
const PrimaryAction = tw(
  Link
)` px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-[#1A150F] border-2 font-bold shadow transition duration-300 text-gray-100 hocus:text-gray-200 focus:outline-none focus:shadow-outline text-center`;
const SocialContainer = tw.div`space-x-2 space-y-3 lg:space-y-0 grid grid-cols-5 lg:grid-cols-5 mt-12 w-full lg:w-3/4`;
const SocialLink = tw.a`text-gray-400 hover:text-gray-500 w-1/2 justify-self-center`;
const FlexEndDiv = tw.div`flex flex-col lg:place-items-end	place-items-center`;
const social = [
  {
    name: 'Facebook',
    href: '#',
    icon: FaFacebook,
  },
  {
    name: 'Instagram',
    href: '#',
    icon: FaInstagram,
  },
  {
    name: 'Twitter',
    href: '#',
    icon: FaXTwitter,
  },
  {
    name: 'TikTok',
    href: '#',
    icon: FaTiktok,
  },
  {
    name: 'LinkedIn',
    href: '#',
    icon: FaLinkedin,
  },
];

export default () => {
  return (
    <Container>
      <InnerContainer>
        <Grid>
          <ContentWrapper>
            <ContentInner>
              <Title>Innovate with us</Title>
              <Description>
                Automate and simplify your business operations with easy and secure solutions
              </Description>
              <ActionCard>
                <PrimaryAction to={'/signup'}>START TODAY</PrimaryAction>
              </ActionCard>
            </ContentInner>
          </ContentWrapper>
          <SmallHiddenDiv></SmallHiddenDiv>
          <FlexEndDiv>
            <Image src={large_email_icon} />
            <SocialContainer>
              {social.map(item => (
                <SocialLink key={item.name} href={item.href}>
                  <item.icon size={'auto'} />
                </SocialLink>
              ))}
            </SocialContainer>
          </FlexEndDiv>
        </Grid>
      </InnerContainer>
    </Container>
  );
};
