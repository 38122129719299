import design_1 from 'assets/images/RWH-Welcome-2.png';
import design_2 from 'assets/images/RWH-Welcome-3.png';
import tw from 'twin.macro';

const Container = tw.div`bg-primary-200`;
const InnerContainer = tw.div`mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8`;
const Section = tw.section``;
const FlexContainer = tw.div`flex flex-col items-center text-center`;
const Heading = tw.h2`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const Paragraph = tw.p`mt-3 max-w-3xl text-lg text-gray-600`;
const GridContainer = tw.div`mt-16 grid grid-cols-1 gap-x-1 lg:grid-cols-2 place-content-end`;
const GridItem = tw.div`col-span-1`;
const AspectContainer = tw.div` overflow-hidden flex justify-center`;
const Image = tw.img`object-cover object-center rounded-lg border-4 border-black w-1/2`;
const PushedContainer = tw.div`mt-16 w-full flex justify-center`;

export default () => {
  return (
    <Container>
      <InnerContainer>
        {/* Details section */}
        <Section aria-labelledby="details-heading">
          <FlexContainer>
            <Heading id="details-heading">Keep your forgotten flows refreshed</Heading>
            <Paragraph>
              Put multiple variants up against each other and allow MailBandit to ​track revenue and
              dynamically send out the best performing one, in ​real time.{' '}
            </Paragraph>
          </FlexContainer>

          <GridContainer>
            <GridItem>
              <AspectContainer>
                <Image
                  src={design_1}
                  alt="Drawstring top with elastic loop closure and textured interior padding."
                />
              </AspectContainer>
            </GridItem>
            <GridItem>
              <AspectContainer>
                <PushedContainer>
                  <Image src={design_2} alt="Front zipper pouch with included key ring." />
                </PushedContainer>
              </AspectContainer>
            </GridItem>
          </GridContainer>
        </Section>
      </InnerContainer>
    </Container>
  );
};
